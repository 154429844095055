import React from 'react';
import FontAwesome from 'react-fontawesome';

export const NavigationBar = () => (
  <div className='navigationBar'>
    <div className='row'>
      <div className='col'>
        <FontAwesome
          className='super-crazy-colors'
          name='envelope-open'
          style={{ marginRight: '5px' }}
        ></FontAwesome>
        <a className='para-left' href='mailto:sales@globaltuch.com'>
          sales@globaltuch.com
        </a>
      </div>
      <div className='col'>
        <FontAwesome
          className='super-crazy-colors'
          name='phone-square'
          style={{ marginRight: '5px' }}
        ></FontAwesome>
        <a className='para-right' href='tel:(966) 011-4007543'>
          (966) 011-4007543
        </a>
      </div>
    </div>
  </div>
);
