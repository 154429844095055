import React from 'react';
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const slideItems = [
  { image: require('../assets/pic1.png'), alt: 'slider' },
  { image: require('../assets/pic2.jpeg'), alt: 'slider' },
  { image: require('../assets/pic3.jpg'), alt: 'slider' },
  { image: require('../assets/pic4.jpg'), alt: 'slider' },
  { image: require('../assets/pic5.jpg'), alt: 'slider' },
  { image: require('../assets/pic6.png'), alt: 'slider' },
  { image: require('../assets/pic7.jpg'), alt: 'slider' },
  { image: require('../assets/pic8.jpg'), alt: 'slider' },
];

const SliderComponent = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Container style={{ background: 'white' }}>
      <Slider {...settings}>
        {slideItems.map((item, index) => (
          <div key={index} className='slide-item'>
            <img
              src={item.image}
              alt={item.alt}
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </div>
        ))}
      </Slider>
    </Container>
  );
};

export default SliderComponent;
