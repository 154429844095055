import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
    };
  }

  render() {
    return (
      <div className="mainContainer">
        <img
          className="allImg"
          src={require("../../assets/contactus.png")}
          alt="Contact Us"
        />
        <Container className="jumbo">
          <Row>
            <Col>
              <h3 className="headerColor">Address</h3>
              <p>
                <strong>
                  Riyadh, Kingdom of Saudi Arabia.
                  <br />
                  Phone: (966) 011-4007543
                  <br />
                  Email: sales@globaltuch.com
                </strong>
              </p>
            </Col>
            <Col>
              <img
                className="logoImg"
                src={require("../../assets/logo.png")}
                alt="company logo"
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
