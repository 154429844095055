import React from 'react';


const Footer = () => {
  return (
    <footer>
        <div className="footer-bottom">  
        <p className="text-center">© {new Date().getFullYear()} GlobalTuch. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
